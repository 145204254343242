<template>
  <v-row>
    <v-col cols="6">
      <v-text-field v-model="categoryName" label="Название" outlined dense />

      <AttributeGroupField v-model="attributeGroup" />

      <div class="d-flex align-start" v-if="!attributeGroup && hasInheritedAttributeGroup">
        <v-text-field :value="category.inherited_attribute_group.name" label="Родительская инфомодель" outlined dense disabled />
        <v-btn class="mt-1 ml-3 text-decoration-none" :to="`/infomodels/${category.inherited_attribute_group.id}`" icon>
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import debounce from 'lodash.debounce';
import { mapState, mapActions } from 'vuex';

import AttributeGroupField from '@/components/CanonicalCategories/AttributeGroupField';

export default {
  components: {
    AttributeGroupField,
  },
  computed: {
    ...mapState('canonical_categories', ['category']),
    categoryName: {
      get() {
        return this.category.name;
      },
      set: debounce(async function (value) {
        this.UPDATE_CATEGORY({
          categoryId: this.category.id,
          name: value,
        });
      }, 700),
    },
    attributeGroup: {
      get() {
        return this.category.attribute_group;
      },
      set: debounce(async function (value) {
        this.UPDATE_CATEGORY({
          categoryId: this.category.id,
          attribute_group: value.id,
        });
      }, 700),
    },
    hasInheritedAttributeGroup() {
      return this.category.inherited_attribute_group;
    },
  },
  methods: {
    ...mapActions('canonical_categories', ['UPDATE_CATEGORY']),
  },
};
</script>
