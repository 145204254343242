<template>
  <div class="category">
    <v-col class="my-15 text-center" cols="12" v-if="isLoading">
      <v-progress-circular size="32" indeterminate />
    </v-col>
    <v-col class="text-center" cols="12" v-else-if="hasError">
      <h1>404: категория не найдена</h1>
    </v-col>
    <template v-else>
      <v-col cols="12">
        <h2>Категория</h2>
      </v-col>
      <v-col cols="12">
        <CanonicalCategoryForm />
        <div class="category__divider" />
        <CanonicalCategoryParallels />
      </v-col>
    </template>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import CanonicalCategoryForm from '@/components/CanonicalCategories/CanonicalCategoryForm';
import CanonicalCategoryParallels from '@/components/CanonicalCategories/CanonicalCategoryParallels';

export default {
  components: {
    CanonicalCategoryForm,
    CanonicalCategoryParallels,
  },
  data() {
    return {
      isLoading: false,
      hasError: false,
    };
  },
  computed: {
    ...mapState('canonical_categories', ['category']),
  },
  watch: {
    '$route.params.categoryId': {
      immediate: true,
      async handler(categoryId) {
        this.hasError = false;
        this.isLoading = true;

        try {
          await this.FETCH_CATEGORY(categoryId);
        } catch (e) {
          this.hasError = true;
          if (!e.isAxiosError) throw e;
        } finally {
          this.isLoading = false;
        }
      },
    },
  },
  methods: {
    ...mapActions('canonical_categories', ['FETCH_CATEGORY']),
  },
};
</script>

<style lang="scss" scoped>
.category {
  &__divider {
    margin: 6px 0 12px;
    height: 1px;
    background-color: hsl(0, 0%, 96%);
  }
}
</style>
