<template>
  <div class="d-flex align-start">
    <v-autocomplete v-model="select" :loading="isLoading" :items="attributeGroups" :search-input.sync="search" label="Инфомодель" item-text="name" item-value="id" :allow-overflow="false" return-object outlined hide-no-data dense />

    <v-btn class="mt-1 ml-3 text-decoration-none" :to="categoryEditPath" v-if="select" icon>
      <v-icon>mdi-pencil</v-icon>
    </v-btn>
    <v-btn class="mt-1 ml-3 text-decoration-none" to="/infomodels" icon>
      <v-icon>mdi-plus</v-icon>
    </v-btn>
  </div>
</template>

<script>
import debounce from 'lodash.debounce';
import { mapState, mapActions } from 'vuex';

export default {
  props: {
    value: { type: Object, default: () => null },
  },
  data() {
    return {
      isLoading: false,
      search: null,
    };
  },
  computed: {
    ...mapState('attribute_groups', ['attributeGroups']),
    select: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
    categoryEditPath() {
      return `/attributes/${this.select.id}`;
    },
  },
  watch: {
    search: {
      handler: debounce(async function (val) {
        if (val && val !== this.select?.name) {
          this.querySelections({
            autocomplete: val,
          });
        }
      }, 500),
    },
  },
  mounted() {
    if (this.select) {
      this.querySelections({
        autocomplete: this.select.name,
      });
    }
  },
  methods: {
    ...mapActions('attribute_groups', ['FETCH_ATTRIBUTE_GROUPS']),
    async querySelections(params) {
      this.isLoading = true;

      try {
        await this.FETCH_ATTRIBUTE_GROUPS(params);
      } catch (e) {
        if (!e.isAxiosError) throw e;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
