<template>
  <v-row>
    <v-col cols="6">
      <h2>Категории</h2>

      <v-list>
        <v-list-item v-if="parentCategory" :to="`/categories/${parentCategory.id}`">
          <v-list-item-title v-text="parentCategory.name" />
          <v-list-item-subtitle v-text="'Родительская категория'" />
        </v-list-item>

        <v-list-item v-for="(item, index) in childCategories" :key="index" :to="`/categories/${item.id}`">
          <v-list-item-title v-text="item.name" />
        </v-list-item>
      </v-list>
    </v-col>
  </v-row>
</template>

<script>
import get from 'lodash.get';
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState('canonical_categories', ['category']),
    parentCategory() {
      return get(this.category, 'parent');
    },
    childCategories() {
      return get(this.category, 'children');
    },
  },
};
</script>
